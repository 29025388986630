<footer class="footer">
     
    <div class="d-flex align-items-center justify-content-center footer-nav">

        <ul>
            <li>
                <a [routerLink]="['/dashboard']"><i nz-icon nzType="home" nzTheme="outline"></i> Home </a>
            </li>
            <li>
                <a [routerLink]="['/dashboard/wallet']"><i nz-icon nzType="wallet" nzTheme="outline"></i> Wallet </a>
            </li>
            <li>

                <a [routerLink]="['/dashboard/profile']">
                <i nz-icon nzType="user" nzTheme="outline"></i> 
                Profile
                 </a>
            </li>
        </ul>

    </div>

</footer>